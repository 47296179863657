import {Component} from '@angular/core';
import { navItems } from '../../_nav';
import { Router } from '@angular/router';

import { loadCldr } from '@syncfusion/ej2-base';
import { AuthenticationService } from '@app/services';
import {TranslateService} from "@ngx-translate/core";
import { CookieService } from 'ngx-cookie-service';

loadCldr(
  require('cldr-data/supplemental/numberingSystems.json'),
  require('cldr-data/main/sl/ca-gregorian.json'),
  require('cldr-data/main/sl/numbers.json'),
  require('cldr-data/main/sl/timeZoneNames.json'),
  require('cldr-data/supplemental/weekdata.json') // To load the culture based first day of week
);

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  public navItems = navItems;
  protected translateService: TranslateService;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    public translate: TranslateService,
    private cookieService: CookieService
  ) {    
    translate.addLangs(['sl', 'hr']);
    const browserLang = this.cookieService.get("langSelect");;    
    translate.use(browserLang.match(/sl|hr/) ? browserLang : 'sl');
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
  
  setLanguage(langSelect){    
    this.translate.use(langSelect);
    
    this.cookieService.set("langSelect", langSelect);
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
