import { Component, OnInit } from '@angular/core';
import { data } from './datasource';
import { PageSettingsModel } from '@syncfusion/ej2-angular-grids';

@Component({
  templateUrl: './delovna.component.html'

})
export class DelovnaComponent implements OnInit {

  public data: object[];
  public pageSettings: PageSettingsModel;

  ngOnInit(): void {
      this.data = data;
      this.pageSettings = { pageSize: 6 };
  }

}
