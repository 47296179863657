import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class SubscriberEmailsService {

    constructor(private http: HttpClient) {
    }

    getEmails() {
        return this.http.get<any[]>(`${environment.apiUrl}/subscriberemails/getemails`);
    }
}
